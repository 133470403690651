import React, { useState } from "react";
import { z } from "zod";
import { Input } from '../../components/ui/input';
import { Card } from '../../components/ui/card';
import { Label } from "../../components/ui/label";
import { registerSchema } from "../../Utils/validationSchema";
import { Button } from "../../components/ui/button";
import { Link } from "react-router-dom";
import images from "../../Utils/images";
import { IoMdEye, IoIosEyeOff } from "react-icons/io";
import { toast } from "sonner"
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setUserInfo } from "../../Redux/userActions";
import { registerUser, registerUserGoogle } from "../../Services/index";
import Loader from "../../components/Loader/loader";

const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    const validateForm = () => {
        try {
            registerSchema.parse(formData);
            setErrors({});
        } catch (error) {
            if (error instanceof z.ZodError) {
                setErrors(error.errors.reduce((acc, curr) => {
                    return { ...acc, [curr.path[0]]: curr.message };
                }, {}));
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (touched[name]) {
            try {
                registerSchema.pick({ [name]: true }).parse({ [name]: value });
                setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
            } catch (error) {
                if (error instanceof z.ZodError) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: error.errors[0].message,
                    }));
                }
            }
        }
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        setTouched({ ...touched, [name]: true });

        try {
            registerSchema.pick({ [name]: true }).parse({ [name]: value });
            setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
        } catch (error) {
            if (error instanceof z.ZodError) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: error.errors[0].message,
                }));
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        validateForm();
        console.log(formData);
        if (formData.name === "" || formData.email === "" || formData.password === "" || formData.confirmPassword === "") {
            toast.error("All fields are required");
            return;
        }
        if (formData.password !== formData.confirmPassword) {
            toast.error("Passwords do not match")
            return;
        }
        setLoading(true);
        try {
            const response = await registerUser(formData);
            if (response?.status) {
                dispatch(setUserInfo(response?.data));
                localStorage.setItem("zionix-token", response?.token);
                toast.success(response?.message);
                setLoading(false);
                navigate('/');
            } else {
                toast.error(response?.message);
                setLoading(false);
            }
        }
        catch (error) {
            console.log(error);
            toast.error(error);
            setLoading(false);
        }
    }

    const handleGoogleRegisterSuccess = async (tokenResponse) => {
        setLoading(true);

        const accessToken = tokenResponse.access_token;
        console.log(accessToken);

        try {
            const response = await registerUserGoogle(accessToken);
            console.log(response);
            if (response?.status) {
                dispatch(setUserInfo(response?.data));
                localStorage.setItem("zionix-token", response?.token);
                toast.success(response?.message);
                navigate('/');
                setLoading(false);
            } else {
                toast.error(response?.message);
                setLoading(false);
            }

        } catch (error) {
            console.log(error);
            toast.error(error);
            setLoading(false);
        }
    }

    const googleLogin = useGoogleLogin({ onSuccess: handleGoogleRegisterSuccess });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }
    return (
        <>
            {loading ? <Loader /> :
                <div className="flex items-center justify-center pb-20">
                    <Card className='mt-28 w-1/3 px-20 py-10 bg-white/70'>
                        <h2 className="text-center text-xl font-semibold mb-5">Create an account with <span className="text-[#005b96] underline">ZIONIX</span></h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-2">
                                <Label>Name</Label>
                                <Input
                                    type='text'
                                    name='name'
                                    value={formData.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder='Name'
                                />
                                {touched.name && errors.name && <p className='text-red-500 text-sm italic'>{errors.name}</p>}
                            </div>
                            <div className="mb-2">
                                <Label>Email</Label>
                                <Input
                                    type='email'
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder='Email'
                                />
                                {touched.email && errors.email && <p className='text-red-500 text-sm italic'>{errors.email}</p>}
                            </div>
                            <div className="mb-2">
                                <Label>Phone Number</Label>
                                <Input
                                    type='text'
                                    name='phoneNumber'
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder='Phone Number'
                                />
                                {touched.phoneNumber && errors.phoneNumber && <p className='text-red-500 text-sm italic'>{errors.phoneNumber}</p>}
                            </div>
                            <div className="mb-2 relative">
                                <Label>Password</Label>
                                <div className="relative">
                                    <Input
                                        type={showPassword ? 'text' : 'password'}
                                        name='password'
                                        value={formData.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder='Password'
                                    />
                                    <span
                                        onClick={togglePasswordVisibility}
                                        className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                                    >
                                        {showPassword ? <IoIosEyeOff size={20} /> : <IoMdEye size={20} />}
                                    </span>
                                </div>
                                {touched.password && errors.password && <p className='text-red-500 text-sm italic'>{errors.password}</p>}
                            </div>
                            <div className="relative mt-2">
                                <Label>Confirm Password</Label>
                                <div className="relative">
                                    <Input
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        name='confirmPassword'
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder='Confirm Password'
                                        className="pr-10 pl-3 py-2 border rounded w-full"
                                    />
                                    <span
                                        onClick={toggleConfirmPasswordVisibility}
                                        className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                                    >
                                        {showConfirmPassword ? <IoIosEyeOff size={20} /> : <IoMdEye size={20} />}
                                    </span>
                                </div>
                                {touched.confirmPassword && errors.confirmPassword && (
                                    <p className='text-red-500 text-sm italic'>{errors.confirmPassword}</p>
                                )}
                            </div>
                            <div className="flex flex-col items-center justify-center gap-3">
                                <Button type='submit' className='mt-5 w-full'>Register</Button>
                                <p>Or</p>
                                <div className="flex items-center justify-center gap-3">
                                    <p>Register using:</p>
                                    <img src={images.GOOGLELOGO} height={40} width={40} alt="googleLogo" className="cursor-pointer" onClick={googleLogin} />
                                </div>
                                <p>Already have an account? <Link to="/login" className='text-blue-500 cursor'>Login</Link></p>
                            </div>
                        </form>
                    </Card>
                </div>}
        </>
    );
}

export default Register