import React, { useState, useEffect } from 'react'
import { Card } from '../../components/ui/card'
import { Button } from '../../components/ui/button'
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHeader
} from '../../components/ui/table'
import { Input } from '../../components/ui/input'
import { getDemands, getFiles, processDemands, getResults, createOrder, getUserProfile } from '../../Services/index'
import { useLocation } from 'react-router-dom'
import Loader from '../../components/Loader/loader'
import { toast } from 'sonner'
import { FaCheckCircle } from "react-icons/fa"
import io from "socket.io-client";
import { formatNumber } from '../../Utils/formatCurrency'
import ProfileModal from '../../components/ProfileModal/profileModal'
import { FaCircleQuestion } from "react-icons/fa6";

import PaginationTable from '../../components/Pagination/pagination'


// const socket = io.connect("http://localhost:5000");

const Result = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [file, setFile] = useState(null);
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search);
    const [loading, setLoading] = useState(false)
    const token = localStorage.getItem('zionix-token')
    const [demandedData, setDemandedData] = useState([])
    const [resultsData, setResultsData] = useState([])
    const fileId = urlParams.get('file');
    const [totalPrice, setTotalPrice] = useState(0);
    const [gst, setGst] = useState(0);
    const [totalPartsPrice, setTotalPartsPrice] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalItems, setTotalItems] = useState(0);
    const [resultsLoading, setResultsLoading] = useState(true);
    const [userProfile, setUserProfile] = useState(null);
    const [open, setOpen] = useState(false);
    const [currentItems, setCurrentItems] = useState([]);

    const fetchUserProfile = async () => {
        const response = await getUserProfile(token);
        console.log(response?.data);
        if (response?.status) {
            setUserProfile(response?.data);
        } else {
            console.error(response?.message);
        }
    }

    useEffect(() => {
        fetchUserProfile();
    }, []);

    const handleRowSelect = (index) => {
        setSelectedRows(prevSelectedRows => {
            const newSelectedRows = prevSelectedRows.includes(index) ?
                prevSelectedRows.filter(rowIndex => rowIndex !== index) :
                [...prevSelectedRows, index];

            // Update selectedRowData based on the new selection
            const newSelectedRowData = newSelectedRows.map(rowIndex => {
                return demandedData.find(item => item.index === rowIndex) || null;
            }).filter(item => item !== null);

            setSelectedRowData(newSelectedRowData);
            return newSelectedRows;
        });
    };

    const handleSelectAll = () => {
        if (selectAll) {
            // Deselect all rows
            setSelectedRows([]);
            setSelectedRowData([]);
        } else {
            // Select all rows
            const allRowIndexes = demandedData.map(row => row.index);
            setSelectedRows(allRowIndexes);

            // Update selectedRowData with all rows
            const allRowData = demandedData.filter(item => allRowIndexes.includes(item.index));
            setSelectedRowData(allRowData);
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        const fetchUserProfile = async () => {
            const response = await getUserProfile(token);
            console.log(response?.data);
            if (response?.status) {
                setUserProfile(response?.data);
            } else {
                console.error(response?.message);
            }
        }
        fetchUserProfile();
    }, [open]);


    useEffect(() => {
        const totalSelectedPrice = selectedRows.reduce((accumulator, index) => {
            const row = demandedData.find(item => item.index === index);
            const price = row && !isNaN(parseFloat(row.price)) ? parseFloat(row.price) : 0;
            return accumulator + price;
        }, 0);
        const gst = totalSelectedPrice * 0.18;
        setTotalPrice(totalSelectedPrice.toFixed(2));
        setGst(gst.toFixed(2));
        setTotalPartsPrice((totalSelectedPrice + gst).toFixed(2));
    }, [demandedData, selectedRows]);


    useEffect(() => {
        const getFileData = async () => {
            setLoading(true)
            try {
                const response = await getFiles(token, fileId)
                if (response?.status) {
                    setFile(response?.data)
                } else {
                    toast.error(response?.data?.message);
                }
            } catch (error) {
                toast.error(error.response?.data?.message);
            } finally {
                setLoading(false)
            }
        }
        getFileData()
    }, [fileId, token]);

    useEffect(() => {
        const getDemandedData = async () => {
            setLoading(true)
            try {
                const response = await getDemands(token, fileId)
                if (response?.status) {
                    const initialData = response?.data.map(item => ({
                        index: item.index,
                        demanded_mpn: item.demanded_mpn,
                        demanded_brand: item.demanded_brand,
                        demanded_specs: item.demanded_specs,
                        demanded_quantity: item.demanded_quantity,
                        icon: null,
                        recommended: {
                            recommended_mpn: null,
                            recommended_brand: null,
                        },
                        desc: null,
                        ManuPNo: null,
                        distributer: null,
                        manufacturer: null,
                        quantity: null,
                        unit_price: null,
                        price: null,
                        currency: null,
                        availability: null,
                    }));
                    setDemandedData(initialData)
                    setTotalItems(initialData?.length);
                    setSelectedRows(initialData.map(row => row.index));
                    setSelectAll(true);
                } else {
                    toast.error(response?.data?.message);
                }
            } catch (error) {
                toast.error(error.response?.data?.message);
            } finally {
                setLoading(false)
            }
        }
        getDemandedData()
    }, [fileId, token]);
    console.log('Demanded Data:', userProfile);

    // useEffect(() => {
    //     const processData = async () => {
    //         try {
    //             const response = await processDemands(token, fileId)
    //             if (response?.status) {
    //                 // You can handle the result or update state if needed here
    //             } else {
    //                 toast.error(response?.data?.message);
    //             }
    //         } catch (error) {
    //             toast.error(error.response?.data?.message);
    //         }
    //     }
    //     processData()
    // }, [fileId, token]);
    console.log('totalPrice Data:', totalPrice);

    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(demandedData.slice(indexOfFirstItem, indexOfLastItem));
    }, [currentPage, itemsPerPage, demandedData]);


    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
    const handlePageSizeChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1); // Reset to the first page when changing page size
    };
    console.log('currentItems:', currentItems);
    useEffect(() => {
        const getResultData = async (retries = 0) => {
            setResultsLoading(true);
            try {
                const response = await getResults(token, fileId);
                if (response?.status) {
                    const mapToInitialData = (result) => {
                        return result.map((item) => {
                            const unitPrice = parseFloat(item.unit_price);
                            return {
                                ManuPNo: item.recommended_MPN || null,
                                availability: item.availability || null,
                                currency: null,
                                // demanded_brand: item.brand || "-",
                                // demanded_mpn: item.recommended_MPN || null,
                                // demanded_quantity: item.quantity?.toString() || null,
                                // demanded_specs: item.description || "-",
                                desc: item.description || null,
                                distributer: item.distributer || null,
                                icon: !isNaN(unitPrice) ? true : null,
                                index: item.index,
                                manufacturer: null,
                                price: item.total_price || null,
                                quantity: item.quantity || null,
                                recommended: {
                                    recommended_mpn: item.recommended_MPN || null,
                                    recommended_brand: item.brand || null,
                                },
                                unit_price: item.unit_price || null
                            };
                        });
                    };

                    const newResultsData = mapToInitialData(response?.data || []);
                    console.log('New Results Data:', newResultsData);

                    // Update demandedData with new results
                    const updatedDemandedData = demandedData.map(demandedItem => {
                        const resultItem = newResultsData.find(result => result.index === demandedItem.index);
                        return resultItem ? { ...demandedItem, ...resultItem } : demandedItem;
                    });

                    setDemandedData(updatedDemandedData);
                    setSelectedRowData(updatedDemandedData);

                    if (newResultsData.length < demandedData.length) {
                        // if (retries < 10) {
                        setTimeout(() => getResultData(retries + 1), 10000);
                        // }
                    } else {
                        setResultsLoading(false);
                    }
                } else {
                    // setResultsLoading(false);
                    toast.error(response?.data?.message);
                }
            } catch (error) {
                // setResultsLoading(false);
                toast.error(error.response?.data?.message);
            }
        };
        const timerId = setTimeout(() => getResultData(), 10000);
        return () => clearTimeout(timerId);
    }, [demandedData.length, fileId, token]);

    useEffect(() => {
        if (resultsLoading) {
            toast.info('Fetching results, please wait...');
        } else {
            // Only show success message if results length matches
            const allResultsFetched = demandedData.length && demandedData.every(item => item.price !== null);
            if (allResultsFetched) {
                setResultsLoading(false);
                toast.success('Results fetched successfully');
            }
        }
    }, [resultsLoading, demandedData]);

    const handlePaymet = async () => {
        if (!userProfile) {
            setOpen(true)
            return
        }
        const data = {
            items: selectedRowData,
            fileId: fileId,
            fileName: file?.file_name,
            amount: totalPartsPrice,
            currency: 'INR',
        }
        try {
            const response = await createOrder(token, data)
            if (response?.status) {
                toast.success('Order created successfully')
                console.log('Order created:', response?.data);
                const options = {
                    key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Replace with your Razorpay key ID
                    amount: response?.data?.amount,  // Amount in paise
                    currency: response?.data?.currency,
                    name: userProfile?.shippingBillingName,
                    description: 'Payment for BOM',
                    order_id: response?.data?.id, // Pass the order ID from the response
                    handler: function (paymentResponse) {
                        // Handle successful payment
                        console.log('Payment successful:', paymentResponse);
                        toast.success('Payment successful');
                    },
                    prefill: {
                        name: userProfile?.shippingBillingName,
                        email: userProfile?.shippingEmail,
                        contact: userProfile?.shippingPhoneNumber
                    },
                    notes: {
                        address: `${userProfile?.shippingAddressLine1}, ${userProfile?.shippingAddressLine2}, ${userProfile?.shippingCity}, ${userProfile?.shippingState}, ${userProfile?.shippingPincode}`

                    },
                    theme: {
                        color: '#3399cc'
                    }
                };

                const rzp = new window.Razorpay(options);
                rzp.open();
            } else {
                toast.error(response?.message)
            }
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <>
            {loading ? <Loader /> :
                <div className='mt-20'>
                    <h1 className='flex items-center justify-center text-2xl font-semibold text-black/70'>Price Results</h1>
                    <Card className='flex items-center justify-between mx-10 px-5 my-5 py-5'>
                        <div>
                            <p><span className='font-semibold'>BOM Name:</span> {file?.file_name}</p>
                            <p><span className='font-semibold'>Total Parts:</span> {file?.file_data?.rowData?.length}</p>
                            <p><span className='font-semibold'>BOM ID:</span> {file?.bom_id}</p>
                        </div>
                        <div>
                            <p><span className='font-semibold'>{selectedRows?.length} components selected</span></p>
                            <p className='text-green-950 bg-green-200 p-2 rounded-md text-xs'>Not satisfied with prices,
                                connect <br /> with offline team</p>
                            <Button className='mt-2'>Optimise your Quote</Button>
                        </div>
                        <div>
                            <p><span className='font-semibold'>Total Parts Price:</span> {formatNumber(totalPrice)}</p>
                            <p><span className='font-semibold'>GST @18%:</span> {formatNumber(gst)}</p>
                            <p><span className='font-semibold'>Total Price:</span> {formatNumber(totalPartsPrice)}</p>
                            <Button className='mt-3'
                                disabled={selectedRowData?.length === 0}
                                onClick={handlePaymet}
                            >Proceed to Billing</Button>
                        </div>
                    </Card>
                    <div className='flex items-center justify-center px-10'>
                        <Table className="border border-gray-300 divide-y divide-gray-200 mt-10 mb-10">
                            <TableHeader>
                                <TableRow className='bg-lightGray/10 hover:bg-lightGray/10 divide-x divide-black/20'>
                                    <TableCell className="border border-black/20" >
                                        <Input
                                            className='cursor-pointer w-4'
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </TableCell>
                                    <TableCell className="border border-black/20" >Sr no.</TableCell>
                                    <TableCell className="border border-black/20" >Demanded Part Number</TableCell>
                                    <TableCell className="border border-black/20" >Demanded Manufacturer</TableCell>
                                    <TableCell className="border border-black/20" >Demanded Description</TableCell>
                                    <TableCell className="border border-black/20" >Qty</TableCell>
                                    <TableCell className="border border-black/20" >Match</TableCell>
                                    <TableCell className="border border-black/20" >Result</TableCell>
                                    <TableCell className="border border-black/20" >Description</TableCell>
                                    <TableCell className="border border-black/20" >Qty</TableCell>
                                    {/* <TableCell className="border border-black/20" >Distributer</TableCell> */}
                                    <TableCell className="border border-black/20" >Unit Price (INR)</TableCell>
                                    <TableCell className="border border-black/20" >Total Price (INR)</TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody className='bg-white'>
                                {currentItems?.map((item, index) => (
                                    <TableRow key={index} className="divide-x divide-gray-200">
                                        <TableCell className="border border-gray-300">
                                            <Input
                                                className='cursor-pointer w-4'
                                                type="checkbox"
                                                checked={selectedRows?.includes(item.index)}
                                                onChange={() => handleRowSelect(item.index)}
                                            />
                                        </TableCell>
                                        <TableCell className="border border-gray-300">{index + 1 + (currentPage - 1) * itemsPerPage}</TableCell>
                                        <TableCell className="border border-gray-300">{item.demanded_mpn}</TableCell>
                                        <TableCell className="border border-gray-300">{item.demanded_brand}</TableCell>
                                        <TableCell className="border border-gray-300">{item.demanded_specs}</TableCell>
                                        <TableCell className="border border-gray-300">{item.demanded_quantity}</TableCell>
                                        <TableCell className="border border-gray-300">{item.icon ? <FaCheckCircle size={20} color='green' className='bg-green rounded-full ' /> : <FaCircleQuestion size={20} color='#FFA500' className='bg-white rounded-full ' />}</TableCell>
                                        <TableCell className="border border-gray-300 text-center">{item.recommended?.recommended_mpn} <br /> {item.recommended?.recommended_brand}</TableCell>
                                        <TableCell className="border border-gray-300">{item.desc}</TableCell>
                                        <TableCell className="border border-gray-300">{item.quantity}</TableCell>
                                        {/* <TableCell className="border border-gray-300">{item.distributer}</TableCell> */}
                                        <TableCell className="border border-gray-300">
                                            {isNaN(parseFloat(item.unit_price)) || !isFinite(item.unit_price)
                                                ? item.unit_price
                                                : parseFloat(item.unit_price).toFixed(2)}
                                        </TableCell>
                                        <TableCell className="border border-gray-300">
                                            {isNaN(parseFloat(item.price)) || !isFinite(item.price)
                                                ? item.price
                                                : parseFloat(item.price).toFixed(2)}
                                        </TableCell>



                                        {/* Add other columns here */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                    {currentItems?.length > 0 && <div>
                        <PaginationTable
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            handlePageChange={handlePageChange}
                            itemsPerPage={itemsPerPage}
                            setItemsPerPage={setItemsPerPage}
                            totalItems={totalItems}
                        />
                    </div>}
                </div>
            }
            <ProfileModal open={open} setOpen={setOpen} />
        </>
    )
}


export default Result