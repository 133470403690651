import React from 'react'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const navigate = useNavigate()
  return (
    <div className='bg-darkGray text-white flex items-center justify-between px-10 py-7'>
        <div>
            <h2 className='text-2xl mb-2'>Contact Us</h2>
            <p className='text-sm'>#677, 1st Floor, 27th Main, 13th Cross, HSR Layout, Sector 1, Bangalore, Karnataka, India - 560 102</p>
            <p className='text-sm'>9 A.M to 7:30 P.M, Monday-Saturday (IST)</p>
            <p className='text-sm'>Phone Number: +91 77440 26990</p>
            <p className='text-sm'>Email: info@zionix.in</p>
        </div>
        <div className='text-sm'>
            <p><span className='cursor-pointer' onClick={()=>navigate("/terms-and-conditions")}>Terms & Conditions</span> | <span className='cursor-pointer' onClick={()=>navigate("/privacy-policy")}>Privacy Policy</span></p>
            <p>© 2024 Zionix All Rights Reserved.</p>
            <p>CRLM Bharat Tech Pvt. Ltd.</p>
            <p>CIN No.: U46529PN2023PTC222112</p>
        </div>
    </div>
  )
}

export default Footer