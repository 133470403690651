import React, { useState, useEffect } from 'react'
import { getOrderItems, getUserProfile } from '../../Services/index'
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHeader
} from '../../components/ui/table'
import { formatNumber } from '../../Utils/formatCurrency'
import { Card } from '../../components/ui/card'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from '../../components/ui/button'
import PaginationTable from '../../components/Pagination/pagination'
import Loader from '../../components/Loader/loader'

const ViewOrderItems = () => {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const orderId = urlParams.get('orderId')
    const user = useSelector(state => state.user.userInfo);
    const [ordersItems, setOrdersItems] = useState([]);
    const token = localStorage.getItem('zionix-token');
    const [userProfile, setUserProfile] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [currentItems, setCurrentItems] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [totalPrice, setTotalPrice] = useState(0);
    const [gst, setGst] = useState(0);
    const [totalPartsPrice, setTotalPartsPrice] = useState(0);

    const fetchUserProfile = async () => {
        setLoading(true);
        const response = await getUserProfile(token);
        console.log(response?.data);
        if (response?.status) {
            setUserProfile(response?.data);
            setLoading(false);
        } else {
            console.error(response?.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchAllOrders = async () => {
            try{
                setLoading(true);
                const response = await getOrderItems(token, orderId);
                console.log(response);
                if (response?.success) {
                    setLoading(false);
                    setOrdersItems(response?.data);
                    setTotalItems(response?.data?.items?.length);
                }else{
                    console.error(response?.message);
                    setLoading(false);
                }
            }
            catch(error){
                setLoading(false);
                console.error(error);
            }
        }
        fetchAllOrders();
        fetchUserProfile();
    }, [])

    console.log(ordersItems);

    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(ordersItems?.items?.slice(indexOfFirstItem, indexOfLastItem));
    }, [currentPage, itemsPerPage, ordersItems]);
    
    
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
    const handlePageSizeChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1); // Reset to the first page when changing page size
    };

    useEffect(() => {
        const totalSelectedPrice = currentItems?.reduce((acc, item) => acc + parseFloat(item?.price), 0);
        const gst = totalSelectedPrice * 0.18;
        setTotalPartsPrice(totalSelectedPrice);
        setGst(gst);
        setTotalPrice(totalSelectedPrice + gst);
    }, [currentItems]);

    console.log("ordersItems", ordersItems);
    console.log("currentItems", currentItems);
    console.log("userProfile", totalItems);
    return (
        <div><div className='mt-28 px-10'>
            <h1 className='text-2xl text-gray-700 text-center font-semibold'>Order Information</h1>
            {loading ? <Loader /> :
            <>
            <Card className="grid grid-cols-3 gap-4 p-4 mt-5 bg-white/70">
                <div>
                    <p>
                        <span className="font-bold">Order Date: </span>
                        {new Date(ordersItems?.createdAt).toLocaleDateString()}
                    </p>
                    <p>
                        <span className="font-bold">Account No.: </span>
                        {user?._id}
                    </p>
                    <p>
                        <span className="font-bold">Order No.: </span>
                        {ordersItems?.createdOrder?.id}
                    </p>
                    <p>
                        <span className="font-bold">Payment Method: </span>
                        {ordersItems?.paymentDetails?.payload?.payment?.entity?.method}
                    </p>
                    <p>
                        <span className="font-bold">Total Parts Price: </span>
                        {formatNumber(totalPartsPrice)}
                    </p>
                    <p>
                        <span className="font-bold">GST @18%: </span>
                        {formatNumber(gst)}
                    </p>
                    <p>
                        <span className="font-bold">Total Price: </span>
                        {formatNumber(totalPrice)}
                    </p>
                </div>
                <div>
                    <h1 className="font-bold">Invoice Address</h1>
                    <p>{ordersItems?.paymentDetails?.payload?.payment?.entity?.notes?.address}</p>
                </div>
                <div>
                    <h1 className="font-bold">Shipping Address</h1>
                    <p>
                        {userProfile?.shippingAddressLine1}, {userProfile?.shippingAddressLine2},<br />
                        {userProfile?.shippingCity}, {userProfile?.shippingState}, {userProfile?.shippingPincode}
                    </p>
                </div>
            </Card>

            <Table className="border border-gray-300 divide-y divide-gray-200 mt-10 mb-10 bg-white/70">
                <TableHeader>
                    <TableRow className='bg-lightGray/10 hover:bg-lightGray/10 divide-x divide-black/20 font-bold text-gray-500'>
                        <TableCell>Sr No.</TableCell>
                        <TableCell>Part number</TableCell>
                        <TableCell>Manufacturer</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Unit price</TableCell>
                        <TableCell>Total price</TableCell>
                    </TableRow>
                </TableHeader>
                {currentItems?.length > 0 ? <TableBody>
                    {currentItems?.map((item, index) => (
                        <TableRow key={index} className="divide-x divide-gray-200">
                            <TableCell className="border border-gray-300">{index + 1 + (currentPage - 1) * itemsPerPage}</TableCell>
                            <TableCell className="border border-gray-300">{item?.recommended?.recommended_mpn}</TableCell>
                            <TableCell className="border border-gray-300">{item?.recommended?.recommended_brand}</TableCell>
                            <TableCell className="border border-gray-300">{item?.desc}</TableCell>
                            <TableCell className="border border-gray-300">{item?.quantity}</TableCell>
                            <TableCell className="border border-gray-300">{formatNumber(item?.unit_price)}</TableCell>
                            <TableCell className="border border-gray-300">{formatNumber(item?.price)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                    : <TableBody>
                        <TableRow>
                            <TableCell colSpan={7} className="text-center font-bold text-xl">No items found</TableCell>
                        </TableRow>
                    </TableBody>}
            </Table>
            {currentItems?.length > 0 && 
            <div>
                <PaginationTable
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    handlePageChange={handlePageChange}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    totalItems={totalItems}
                />
            </div>}
            </>}
        </div>
        </div>
    )
}

export default ViewOrderItems