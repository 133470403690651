import axios from 'axios';

// ************ DEVELOPMENT URL ************
// export const BASE_URL = process.env.REACT_APP_DEV_URL; //Development url Live
// export const SOCKET_URL = 'devurl'; //Development url Live

// ************ localhost ************

// export const BASE_URL = `http://localhost:5000/`;
// export const SOCKET_URL = 'http://localhost:5000/';

// ************ STAGING URL ************
export const BASE_URL = `https://api.zionix.in/`

// ************ LIVE APP URL ************
// export const BASE_URL = process.env.REACT_APP_BACKEND_URL; //Live App URL (With / at the end in .env file)
// export const SOCKET_URL = process.env.REACT_APP_BACKEND_URL;

var header = {
    'Content-Type': 'application/json',
}

export const axiosInstance = axios.create({
    baseURL: `${BASE_URL}api/`,
    headers: header,
});
