import React, { useEffect, useState } from 'react';
import images from '../../Utils/images'
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
  MenubarGroup,
  MenubarLabel
} from '../ui/menubar'
import { Button } from '../ui/button'
import { CiSettings } from "react-icons/ci";
import { IoNewspaperOutline, IoCloudUploadOutline } from "react-icons/io5";
import { GrMapLocation } from "react-icons/gr";
import { LuClipboardList } from "react-icons/lu";
import { TfiEmail } from "react-icons/tfi";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clearUserInfo } from '../../Redux/userActions';
import { toast } from 'sonner';

const Navbar = () => {

  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.userInfo);
  console.log(user);
  const location = useLocation();
  const [userImage, setUserImage] = useState(null);

  useEffect(() => {
    setUserImage(user?.profilePicture
      || "https://ui-avatars.com/api/?name=" + user?.name);
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLogout = () => {
    toast.success('Logged out successfully');
    localStorage.removeItem('zionix-token');
    dispatch(clearUserInfo());
    navigate('/login');
  }

  return (
    <div className={`fixed top-0 left-0 right-0 z-50 px-10 py-4 flex items-center justify-between bg-darkGray transition backdrop-blur-md ${scrolled ? 'bg-opacity-80' : 'bg-opacity-100'}`}>
      <div>
        <img src={images.HEADERLOGO} alt="logo" height={100} width={100} onClick={() => navigate('/')} className='cursor-pointer' />
      </div>
      <div className='text-white flex items-center justify-center gap-5'>
        <ul className="flex items-center justify-center gap-5 text-lg font-bold cursor-pointer">
          <li className="relative group">
            <span onClick={() => navigate('/')} className={`pb-1 relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[2px] after:w-full after:bg-current after:scale-x-0 after:origin-left after:transition-transform after:duration-300 group-hover:after:scale-x-100 hover:duration-300 hover:text-white/70 ${location.pathname === '/' ? 'after:scale-x-100' : ''
              } `}>
              Home
            </span>
          </li>
          <li className="relative group">
            <span className={`pb-1 relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[2px] after:w-full after:bg-current after:scale-x-0 after:origin-left after:transition-transform after:duration-300 group-hover:after:scale-x-100 hover:duration-300 hover:text-white/70 ${location.pathname === '/enterpriseSolutions' ? 'after:scale-x-100' : ''
              }`}>
              Enterprise solution
            </span>
          </li>
          <li className="relative group">
            <span onClick={() => navigate('/uploadBOM')} className={`pb-1 relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[2px] after:w-full after:bg-current after:scale-x-0 after:origin-left after:transition-transform after:duration-300 group-hover:after:scale-x-100 hover:duration-300 hover:text-white/70 ${location.pathname === '/uploadBOM' ? 'after:scale-x-100' : ''
              }`}>
              Upload BOM
            </span>
          </li>
        </ul>

        {user ? <Menubar className="bg-transparent border-none">
          <MenubarMenu>
            <MenubarTrigger className="bg-transparent focus:outline-none focus:bg-transparent active:bg-transparent">
              <Avatar>
                <AvatarImage
                  src={user?.profilePicture || `https://ui-avatars.com/api/?name=${encodeURIComponent(user?.name || '')}`}
                />

                <AvatarFallback>CN</AvatarFallback>
              </Avatar>
            </MenubarTrigger>
            <MenubarContent className="min-w-max w-auto mr-5">
              <MenubarLabel disabled className="font-bold text-m">
                {user?.name}
              </MenubarLabel>
              <MenubarLabel disabled className="text-sm font-normal text-lightGray flex items-center">
                <TfiEmail size={18} className='mr-2' /> <span>{user?.email}</span>
              </MenubarLabel>
              <MenubarSeparator />
              <MenubarGroup>
                {/* <MenubarLabel>My Orders</MenubarLabel> */}
                <MenubarItem onClick={() => navigate("/orderHistory")}>
                  <LuClipboardList size={18} className='mr-2' /> Order History
                </MenubarItem>
                {/* <MenubarItem>
                  <GrMapLocation size={18} className='mr-2' /> Track order
                </MenubarItem> */}
              </MenubarGroup>
              <MenubarSeparator />
              <MenubarGroup>
                {/* <MenubarLabel>Acquisition Tools</MenubarLabel> */}
                <MenubarItem onClick={() => navigate('/uploadBOM')}>
                  <IoCloudUploadOutline size={20} className='mr-2' /> BOM Upload
                </MenubarItem>
                <MenubarItem onClick={() => navigate('/quoteHistory')}>
                  <IoNewspaperOutline size={20} className='mr-2' /> Quotes History
                </MenubarItem>
              </MenubarGroup>
              <MenubarSeparator />
              <MenubarItem className="font-semibold" onClick={() => navigate('/userProfile')}>
                <CiSettings size={25} className='mr-2' /> Profile Settings
              </MenubarItem>
              <MenubarSeparator />
              <MenubarItem className="hover:bg-transparent">
                <Button className="w-full" onClick={handleLogout}>
                  Logout
                </Button>
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>
        </Menubar> :
          <Button type='submit' className='hover:bg-darkBlue/50' onClick={() => navigate('/login')}>Login</Button>}
      </div>
    </div>
  );
};

export default Navbar