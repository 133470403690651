import React, { useEffect, useState } from 'react'
import { Card } from '../../components/ui/card'
import { Label } from '../../components/ui/label'
import { Input } from '../../components/ui/input'
import { getFiles, checkQuantity, storeDemands, processDemands } from '..//../Services/index'
import TableDropDown from '../../components/TableDropDown/tableDropDown'
import { Button } from '../../components/ui/button'
import {
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
} from '../../components/ui/table'
import Loader from '../../components/Loader/loader'
import { toast } from 'sonner'
import { useLocation, useNavigate } from 'react-router-dom'
import PaginationTable from '../../components/Pagination/pagination'

const AssignColumns = () => {
    const [fileName, setFileName] = useState(null)
    const token = localStorage.getItem('zionix-token');
    const [columnLength, setColumnsLength] = useState(0)
    const [rows, setRows] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [header, setHeader] = useState("");
    const [quantityIndex, setQuantityIndex] = useState(null);
    const [partNumberIndex, setPartNumberIndex] = useState(null);
    const [manufacturerIndex, setManufacturerIndex] = useState(null);
    const [descriptionIndex, setDescriptionIndex] = useState(null);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const fileId = urlParams.get('file');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [currentItems, setCurrentItems] = useState([]);
    const [totalItems, setTotalItems] = useState(0);

    

    useEffect(() => {
        console.log('fileId', fileId)
        setLoading(true)
        const getFileData = async () => {
            const response = await getFiles(token, fileId)
            if (response?.status) {
                setColumnsLength(response?.data?.file_data?.rowLen)
                setRows(response?.data?.file_data?.rowData)
                setTotalItems(response?.data?.file_data?.rowData?.length)
                setFileName(response?.data?.file_name)
                console.log('response', response)
                setLoading(false)
            }
            else {
                console.log('error', response)
                setLoading(false)
            }
        }
        getFileData()
    }, [token])
    console.log('columnLength', columnLength)
    console.log('rows', rows)

    const handleSelectChange = (index, value) => {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions[index] = value;
        setSelectedOptions(newSelectedOptions);
        if(value === "Quantity*"){
            setQuantityIndex(index+1)
        }
        if(value === "Part Number*"){
            setPartNumberIndex(index+1)
        }
        if(value === "Manufacturer"){
            setManufacturerIndex(index+1)
        }
        if(value === "Description"){
            setDescriptionIndex(index+1)
        }
    };

    const checkQunatity = async () => {
        setLoading(true)
        const requestObj = {
            rowData: rows, // an array of objects -> .index,
            header: header,
            QuantityIndex: quantityIndex,
            PartNumIndex: partNumberIndex,
            ManufacturerIndex: manufacturerIndex,
            DescriptionIndex: descriptionIndex,
          };
          console.log('requestObj', requestObj)
        try {
            const response = await checkQuantity(token, requestObj)
            if(response?.status){
                const responseStore = await storeDemands(token, requestObj, fileId)
                if(responseStore?.status){
                    console.log('responseStore', responseStore)
                    toast.success(response?.message)
                    setLoading(false)
                    processDemands(token, fileId)
                    navigate(`/result?file=${fileId}`)
                }
                else{
                    console.log('error', responseStore)
                    toast.error(responseStore?.message)
                    setLoading(false)
                }
            }
            else{
                console.log('error', response)
                toast.error(response?.message)
                setLoading(false)
            }
        } catch (error) {
            toast.error('Error checking quantity')
            setLoading(false)

        }
    }
    console.log("header", header)

    const requiredFields = ["Quantity*", "Part Number*"];
    const hasRequiredFields = requiredFields.every(field => selectedOptions.includes(field));

    const buttonDisabled = rows?.length === 0 || selectedOptions?.length === 0 || !hasRequiredFields || !header;

    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(rows?.slice(indexOfFirstItem, indexOfLastItem));
    }, [currentPage, itemsPerPage, rows]);
    
    
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
    const handlePageSizeChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1); // Reset to the first page when changing page size
    };
    return (
        <>
            {loading ? <Loader /> :
                <div className='mt-20'>
                    <div className='text-center'>
                        <h1 className='text-2xl text-darkGray font-semibold'>Assign Columns</h1>
                        <h2 className='mt-2 color-black text-lg'>File name: {fileName}</h2>
                    </div>
                    <div>
                        <Card className="flex flex-col items-center justify-center w-2/12 ml-20 bg-gradient-to-r from-slate-200 to-slate-500'">
                            <div className='px-5 pt-5'>
                                <p>Does table have headers?</p>
                            </div>
                            <hr className="border-t-2 border-gray-300 mt-4 w-full" />
                            <div className="flex items-center space-x-4 p-4">
                                <div className="flex items-center space-x-2">
                                    <Input
                                        id="yes"
                                        type="radio"
                                        name="headers"
                                        className="form-radio"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if(value === "on"){
                                                setHeader("Yes")
                                            }
                                        }}
                                    />
                                    <Label htmlFor="yes" className="cursor-pointer">
                                        Yes
                                    </Label>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <Input
                                        id="no"
                                        type="radio"
                                        name="headers"
                                        className="form-radio"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            // console.log('value', value)
                                            if(value === "on"){
                                                setHeader("No")
                                            }
                                        }}
                                    />
                                    <Label htmlFor="no" className="cursor-pointer">
                                        No
                                    </Label>
                                </div>
                            </div>
                        </Card>
                        <Button
                            className='ml-20 mt-5'
                            disabled= {buttonDisabled}
                            onClick={checkQunatity}
                        >Proceed</Button>
                    </div>
                    <div className='flex items-center justify-center px-10'>
                        <Table className="border border-gray-300 divide-y divide-gray-200 mt-10 mb-10">
                            <TableHeader>
                                <TableRow className='bg-lightGray/10 hover:bg-lightGray/10 divide-x divide-black/20'>
                                    <TableCell className="border border-black/20">SI No.</TableCell>
                                    {Array.from({ length: columnLength }, (_, index) => (
                                        <TableCell key={index} className="border border-gray-300">
                                            <TableDropDown
                                                selectedOption={selectedOptions[index]}
                                                setSelectedOption={(value) => handleSelectChange(index, value)}
                                                disabledOptions={selectedOptions.filter((_, i) => i !== index)}
                                            />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            {rows?.length > 0 ? <TableBody className='bg-white/70'>
                                {currentItems?.map((row, rowIndex) => (
                                    <TableRow key={rowIndex} className="divide-x divide-gray-200">
                                        <TableCell className="border border-gray-300">{rowIndex + 1 + (currentPage - 1) * itemsPerPage}</TableCell>
                                        {row.data.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex} className="border border-gray-300">{cell}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody> :
                                <TableBody>
                                    <TableRow className="divide-x divide-gray-200">
                                        <TableCell className="border border-gray-300">No data found</TableCell>
                                    </TableRow>
                                </TableBody>}
                        </Table>
                    </div>
                   {currentItems?.length >0 && <div>
                <PaginationTable
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    handlePageChange={handlePageChange}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    totalItems={totalItems}
                />
            </div>}

                </div>}
        </>
    )
}

export default AssignColumns