/* eslint-disable import/no-anonymous-default-export */
export default{
    LOGIN: 'login',
    REGISTER: 'register',
    UPLOAD_BOM: 'process',
    GETFILE: 'getfile/',
    CHECKQUANTITY: 'check-quantity',
    STORE: 'store/',
    GETDATA: 'get/',
    PROCESSDATA: 'process/',
    SAVE: 'save',
    GET: 'get',
    CREATEORDER: 'create-Order',
    WEBHOOK: 'webhook',
    GETALLORDERS: 'all',
    GETORDERITEMS: 'items/',
    GETFILEDATA: 'getFileData'
}