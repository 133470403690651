import apiEndpointConstants from "../Config/apiEndpointConstants";
import apiTypeConstants from "../Config/apiTypeConstants";
import { axiosInstance } from "../Config/axiosConfig";

export const registerUser = async (data) => {
    try {
        const response = await axiosInstance.post(
        `${apiTypeConstants.AUTH}${apiEndpointConstants.REGISTER}`,
        data,
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const registerUserGoogle = async (googleAccessToken) => {
    try {
        console.log("googleAccessToken new", googleAccessToken);
        const response = await axiosInstance.post(
        `${apiTypeConstants.AUTH}${apiEndpointConstants.REGISTER}`,
        {googleAccessToken},
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const loginUser = async (data) => {
    try {
        const response = await axiosInstance.post(
        `${apiTypeConstants.AUTH}${apiEndpointConstants.LOGIN}`,
        data,
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const loginUserGoogle = async (googleAccessToken) => {
    try {
        const response = await axiosInstance.post(
        `${apiTypeConstants.AUTH}${apiEndpointConstants.LOGIN}`,
        {googleAccessToken},
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const uploadBOM = async (token, file) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
        const response = await axiosInstance.post(
        `${apiTypeConstants.FILE}${apiEndpointConstants.UPLOAD_BOM}`, formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `${token}`,
            },
        }
        )
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const getFiles = async (token, fileId) => {
    try {
        const response = await axiosInstance.get(
        `${apiTypeConstants.FILE}${apiEndpointConstants.GETFILE}${fileId}`,
        {
            headers: {
                Authorization: `${token}`,
            },
        }
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const checkQuantity = async (token, data) => {
    try {
        const response = await axiosInstance.post(
        `${apiTypeConstants.FILE}${apiEndpointConstants.CHECKQUANTITY}`,
        data,
        {
            headers: {
                Authorization: `${token}`,
            },
        }
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
} 

export const storeDemands = async (token, requestObj, fileId) => {
    try {
        const response = await axiosInstance.post(
        `${apiTypeConstants.DEMANDEDDATA}${apiEndpointConstants.STORE}${fileId}`,
        {requestObj},
        {
            headers: {
                Authorization: `${token}`,
            },
        }
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const getDemands = async (token, fileId) => {
    try {
        const response = await axiosInstance.get(
        `${apiTypeConstants.DEMANDEDDATA}${apiEndpointConstants.GETDATA}${fileId}`,
        {
            headers: {
                Authorization: `${token}`,
            },
        }
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const getResults = async (token, fileId) => {
    try {
        const response = await axiosInstance.get(
        `${apiTypeConstants.RESULT}${fileId}`,
        {
            headers: {
                Authorization: `${token}`,
            },
        }
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const processDemands = async (token, fileId) => {
    try {
        const response = await axiosInstance.get(
        `${apiTypeConstants.RESULT}${apiEndpointConstants.PROCESSDATA}${fileId}`,
        {
            headers: {
                Authorization: `${token}`,
            },
        }
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const saveUserProfile = async (token, data) => {
    try {
        const response = await axiosInstance.post(
        `${apiTypeConstants.USERPROFILE}${apiEndpointConstants.SAVE}`,
        data,
        {
            headers: {
                Authorization: `${token}`,
            },
        }
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const getUserProfile = async (token) => {
    try {
        const response = await axiosInstance.get(
        `${apiTypeConstants.USERPROFILE}${apiEndpointConstants.GET}`,
        {
            headers: {
                Authorization: `${token}`,
            },
        }
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const createOrder = async (token, data) => {
    try {
        const response = await axiosInstance.post(
        `${apiTypeConstants.PAYMENT}${apiEndpointConstants.CREATEORDER}`,
        data,
        {
            headers: {
                Authorization: `${token}`,
            },
        }
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const getAllOrders = async (token) => {
    try {
        const response = await axiosInstance.get(
        `${apiTypeConstants.ORDER}${apiEndpointConstants.GETALLORDERS}`,
        {
            headers: {
                Authorization: `${token}`,
            },
        }
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const getOrderItems = async (token, orderId) => {
    try {
        const response = await axiosInstance.get(
        `${apiTypeConstants.ORDER}${apiEndpointConstants.GETORDERITEMS}${orderId}`,
        {
            headers: {
                Authorization: `${token}`,
            },
        }
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}

export const getFileData = async (token, fileId) => {
    try {
        const response = await axiosInstance.get(
        `${apiTypeConstants.FILE}${apiEndpointConstants.GETFILEDATA}`,
        {
            headers: {
                Authorization: `${token}`,
            },
        }
        );
        return response?.data;
    } catch (error) {
        console.log(error.response);
        return error.response?.data;
    }
}