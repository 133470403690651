import React, { useState, useEffect } from 'react'
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
} from '../ui/sheet'
import { Input } from '../ui/input'
import { Button } from '../ui/button'
import { Label } from '../ui/label'
import { profileSchema } from '../../Utils/validationSchema'
import { ScrollArea } from "../ui/scroll-area"
import Loader from '../Loader/loader'
import {toast} from 'sonner'
import { saveUserProfile, getUserProfile } from '../../Services/index'
import { useSelector } from 'react-redux'

import { z } from 'zod'

const ProfileModal = ({ open, setOpen }) => {
    const user = useSelector((state) => state.user.userInfo);
    const [userProfile, setUserProfile] = useState({});
    const [formData, setFormData] = useState({})
    const token = localStorage.getItem('zionix-token');

    const fetchUserProfile = async () => {
        const response = await getUserProfile(token);
        console.log(response?.data);
        if(response?.status){
            setUserProfile(response?.data);
        } else {
            console.error(response?.message);
        }
    }

    useEffect(() => {       
        fetchUserProfile();
    }, []);
    
    useEffect(() => {
        if (userProfile) {
            setFormData({
                userId: userProfile.userId || user?._id || '',
                firstName: userProfile.firstName || user?.name || '',
                lastName: userProfile.lastName || '',
                email: userProfile.email || user?.email || '',
                phoneNumber: userProfile.phoneNumber || user?.phoneNumber || '',
                billingName: userProfile.billingName || '',
                gstin: userProfile.gstin || '',
                addressLine1: userProfile.addressLine1 || '',
                addressLine2: userProfile.addressLine2 || '',
                city: userProfile.city || '',
                state: userProfile.state || '',
                pincode: userProfile.pincode || '',
                shippingFirstName: userProfile.shippingFirstName || '',
                shippingLastName: userProfile.shippingLastName || '',
                shippingEmail: userProfile.shippingEmail || '',
                shippingPhoneNumber: userProfile.shippingPhoneNumber || '',
                shippingBillingName: userProfile.shippingBillingName || '',
                shippingGstin: userProfile.shippingGstin || '',
                shippingAddressLine1: userProfile.shippingAddressLine1 || '',
                shippingAddressLine2: userProfile.shippingAddressLine2 || '',
                shippingCity: userProfile.shippingCity || '',
                shippingState: userProfile.shippingState || '',
                shippingPincode: userProfile.shippingPincode || '',
            });
        }
    }, [userProfile, user]);
    
    console.log("userProfile,", userProfile);
    console.log("formData,", formData);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    const validateForm = () => {
        try {
            profileSchema.parse(formData);
            setErrors({});
        } catch (error) {
            if (error instanceof z.ZodError) {
                setErrors(error.errors.reduce((acc, curr) => {
                    return { ...acc, [curr.path[0]]: curr.message };
                }, {}));
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (touched[name]) {
            try {
                profileSchema.pick({ [name]: true }).parse({ [name]: value });
                setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
            } catch (error) {
                if (error instanceof z.ZodError) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: error.errors[0].message,
                    }));
                }
            }
        }
    };

    const handleBlur = (e) => {
        const { name } = e.target;
        setTouched({ ...touched, [name]: true });

        try {
            profileSchema.pick({ [name]: true }).parse({ [name]: formData[name] });
            setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
        } catch (error) {
            if (error instanceof z.ZodError) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: error.errors[0].message,
                }));
            }
        }
    }

    const handleSelectCheckbox = (e) => {
        if (e.target.checked) {
            setFormData({
                ...formData,
                shippingFirstName: formData.firstName,
                shippingLastName: formData.lastName,
                shippingEmail: formData.email,
                shippingPhoneNumber: formData.phoneNumber,
                shippingBillingName: formData.billingName,
                shippingGstin: formData.gstin,
                shippingAddressLine1: formData.addressLine1,
                shippingAddressLine2: formData.addressLine2,
                shippingCity: formData.city,
                shippingState: formData.state,
                shippingPincode: formData.pincode,
            });
        } else {
            setFormData({
                ...formData,
                shippingFirstName: '',
                shippingLastName: '',
                shippingEmail: '',
                shippingPhoneNumber: '',
                shippingBillingName: '',
                shippingGstin: '',
                shippingAddressLine1: '',
                shippingAddressLine2: '',
                shippingCity: '',
                shippingState: '',
                shippingPincode: '',
            });
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        setTouched({
            firstName: true,
            lastName: true,
            email: true,
            phoneNumber: true,
            billingName: true,
            gstin: true,
            addressLine1: true,
            addressLine2: true,
            city: true,
            state: true,
            pincode: true,
            shippingFirstName: true,
            shippingLastName: true,
            shippingEmail: true,
            shippingPhoneNumber: true,
            shippingBillingName: true,
            shippingGstin: true,
            shippingAddressLine1: true,
            shippingAddressLine2: true,
            shippingCity: true,
            shippingState: true,
            shippingPincode: true,
        });

        validateForm();
            setLoading(true);
            try{
                const response = await saveUserProfile(token, formData);
                console.log(response);
                if(response?.status){
                    toast.success(response?.message);
                    setLoading(false);
                    setOpen(false);
                    fetchUserProfile()
                } else {
                    toast.error(response?.message);
                    setLoading(false);
                }
            }
            catch(error){
                console.log(error);
                toast.error('Profile update failed');
                setLoading(false);
            }
    }

    return (
        <Sheet open={open} onOpenChange={setOpen}>
            <SheetContent>
            {loading ? <Loader /> :
                <ScrollArea className="h-full w-full">
                    <SheetHeader>
                        <SheetTitle>Update your profile</SheetTitle>
                        <SheetDescription>
                            These details are required in order to make payments.
                        </SheetDescription>
                    </SheetHeader>
                    <div className='px-2'>
                        <form className="grid grid-cols-1 gap-4" onSubmit={handleSubmit}>
                            {/* Personal Information */}
                            <div className='mt-2'>
                                <Label>First Name</Label>
                                <Input
                                    required
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.firstName && errors.firstName && <p className='text-red-500 text-sm italic'>{errors.firstName}</p>}
                            </div>

                            <div>
                                <Label>Last Name</Label>
                                <Input
                                    required
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.lastName && errors.lastName && <p className='text-red-500 text-sm italic'>{errors.lastName}</p>}
                            </div>

                            <div>
                                <Label>Email</Label>
                                <Input
                                    required
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.email && errors.email && <p className='text-red-500 text-sm italic'>{errors.email}</p>}
                            </div>

                            <div>
                                <Label>Phone Number</Label>
                                <Input
                                    required
                                    type="tel"
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.phoneNumber && errors.phoneNumber && <p className='text-red-500 text-sm italic'>{errors.phoneNumber}</p>}
                            </div>

                            <div>
                                <Label>Billing Name</Label>
                                <Input
                                    required
                                    type="text"
                                    name="billingName"
                                    placeholder="Billing Name"
                                    value={formData.billingName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.billingName && errors.billingName && <p className='text-red-500 text-sm italic'>{errors.billingName}</p>}
                            </div>

                            <div>
                                <Label>{"GSTIN (Optional)"}</Label>
                                <Input
                                    type="text"
                                    name="gstin"
                                    placeholder="GSTIN"
                                    value={formData.gstin}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.gstin && errors.gstin && <p className='text-red-500 text-sm italic'>{errors.gstin}</p>}
                            </div>

                            <div>
                                <Label>Address Line 1</Label>
                                <Input
                                    required
                                    type="text"
                                    name="addressLine1"
                                    placeholder="Address Line 1"
                                    value={formData.addressLine1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.addressLine1 && errors.addressLine1 && <p className='text-red-500 text-sm italic'>{errors.addressLine1}</p>}
                            </div>

                            <div>
                                <Label>{"Address Line 2 (Optional)"}</Label>
                                <Input
                                    type="text"
                                    name="addressLine2"
                                    placeholder="Address Line 2"
                                    value={formData.addressLine2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.addressLine2 && errors.addressLine2 && <p className='text-red-500 text-sm italic'>{errors.addressLine2}</p>}
                            </div>

                            <div>
                                <Label>City</Label>
                                <Input
                                    required
                                    type="text"
                                    name="city"
                                    placeholder="City"
                                    value={formData.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.city && errors.city && <p className='text-red-500 text-sm italic'>{errors.city}</p>}
                            </div>

                            <div>
                                <Label>State</Label>
                                <Input
                                    required
                                    type="text"
                                    name="state"
                                    placeholder="State"
                                    value={formData.state}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.state && errors.state && <p className='text-red-500 text-sm italic'>{errors.state}</p>}
                            </div>

                            <div>
                                <Label>Pincode</Label>
                                <Input
                                    required
                                    type="text"
                                    name="pincode"
                                    placeholder="Pincode"
                                    value={formData.pincode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.pincode && errors.pincode && <p className='text-red-500 text-sm italic'>{errors.pincode}</p>}
                            </div>

                            <div className='flex items-center justify-start'>
                                <Input
                                className='w-4 h-4 mr-2'
                                    type="checkbox"
                                    name="sameAsBilling"
                                    id="sameAsBilling"
                                    onChange={handleSelectCheckbox}
                                />
                                <Label>Shipping Details same as Profile </Label>

                            </div>

                            {/* Shipping Information */}
                            <div>
                                <Label>Shipping First Name</Label>
                                <Input
                                    required
                                    type="text"
                                    name="shippingFirstName"
                                    placeholder="Shipping First Name"
                                    value={formData.shippingFirstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.shippingFirstName && errors.shippingFirstName && <p className='text-red-500 text-sm italic'>{errors.shippingFirstName}</p>}
                            </div>

                            <div>
                                <Label>Shipping Last Name</Label>
                                <Input
                                    required
                                    type="text"
                                    name="shippingLastName"
                                    placeholder="Shipping Last Name"
                                    value={formData.shippingLastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.shippingLastName && errors.shippingLastName && <p className='text-red-500 text-sm italic'>{errors.shippingLastName}</p>}
                            </div>

                            <div>
                                <Label>Shipping Email</Label>
                                <Input
                                    required
                                    type="email"
                                    name="shippingEmail"
                                    placeholder="Shipping Email"
                                    value={formData.shippingEmail}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.shippingEmail && errors.shippingEmail && <p className='text-red-500 text-sm italic'>{errors.shippingEmail}</p>}
                            </div>

                            <div>
                                <Label>Shipping Phone Number</Label>
                                <Input
                                    required
                                    type="tel"
                                    name="shippingPhoneNumber"
                                    placeholder="Shipping Phone Number"
                                    value={formData.shippingPhoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.shippingPhoneNumber && errors.shippingPhoneNumber && <p className='text-red-500 text-sm italic'>{errors.shippingPhoneNumber}</p>}
                            </div>

                            <div>
                                <Label>Shipping Billing Name</Label>
                                <Input
                                    required
                                    type="text"
                                    name="shippingBillingName"
                                    placeholder="Shipping Billing Name"
                                    value={formData.shippingBillingName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.shippingBillingName && errors.shippingBillingName && <p className='text-red-500 text-sm italic'>{errors.shippingBillingName}</p>}
                            </div>

                            <div>
                                <Label>{"Shipping GSTIN (Optional)"}</Label>
                                <Input
                                    type="text"
                                    name="shippingGstin"
                                    placeholder="Shipping GSTIN"
                                    value={formData.shippingGstin}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.shippingGstin && errors.shippingGstin && <p className='text-red-500 text-sm italic'>{errors.shippingGstin}</p>}
                            </div>

                            <div>
                                <Label>Shipping Address Line 1</Label>
                                <Input
                                    required
                                    type="text"
                                    name="shippingAddressLine1"
                                    placeholder="Shipping Address Line 1"
                                    value={formData.shippingAddressLine1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.shippingAddressLine1 && errors.shippingAddressLine1 && <p className='text-red-500 text-sm italic'>{errors.shippingAddressLine1}</p>}
                            </div>

                            <div>
                                <Label>{"Shipping Address Line 2 (Optional)"}</Label>
                                <Input
                                    type="text"
                                    name="shippingAddressLine2"
                                    placeholder="Shipping Address Line 2"
                                    value={formData.shippingAddressLine2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.shippingAddressLine2 && errors.shippingAddressLine2 && <p className='text-red-500 text-sm italic'>{errors.shippingAddressLine2}</p>}
                            </div>

                            <div>
                                <Label>Shipping City</Label>
                                <Input
                                    required
                                    type="text"
                                    name="shippingCity"
                                    placeholder="Shipping City"
                                    value={formData.shippingCity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.shippingCity && errors.shippingCity && <p className='text-red-500 text-sm italic'>{errors.shippingCity}</p>}
                            </div>

                            <div>
                                <Label>Shipping State</Label>
                                <Input
                                    required
                                    type="text"
                                    name="shippingState"
                                    placeholder="Shipping State"
                                    value={formData.shippingState}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.shippingState && errors.shippingState && <p className='text-red-500 text-sm italic'>{errors.shippingState}</p>}
                            </div>

                            <div>
                                <Label>Shipping Pincode</Label>
                                <Input
                                    required
                                    type="text"
                                    name="shippingPincode"
                                    placeholder="Shipping Pincode"
                                    value={formData.shippingPincode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.shippingPincode && errors.shippingPincode && <p className='text-red-500 text-sm italic'>{errors.shippingPincode}</p>}
                            </div>

                            <Button type="submit" className="mt-4" disabled={loading}>
                                {loading ? 'Saving...' : 'Save'}
                            </Button>
                        </form>
                    </div>
                </ScrollArea>}
            </SheetContent>
        </Sheet>
    )
}

export default ProfileModal
