import React, { useState } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../ui/select';

const TableDropDown = ({
    selectedOption, 
    setSelectedOption, 
    disabledOptions,
}) => {
    const [options] = useState([
        { id: 1, name: 'Quantity*' },
        { id: 2, name: 'Part Number*' },
        { id: 3, name: 'Manufacturer' },
        { id: 4, name: 'Description' },
    ]);
    return (
        <Select onValueChange={(value) => setSelectedOption(value)}>
            <SelectTrigger className="w-[150px]">
                <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent>
                {options.map((option, index) => (
                    <SelectItem
                        key={option.id}
                        value={option.name}
                        className={selectedOption === option.name ? 'bg-darkBlue/70 text-white' : ''}
                        disabled={disabledOptions.includes(option.name)}
                    >
                        {option.name}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
}

export default TableDropDown;
