import React, { useState } from "react";
import { z } from "zod";
import { Input } from '../../components/ui/input';
import { Card } from '../../components/ui/card';
import { Label } from "../../components/ui/label";
import { loginSchema } from "../../Utils/validationSchema";
import { Button } from "../../components/ui/button";
import { Link } from "react-router-dom";
import images from "../../Utils/images";
import { IoMdEye, IoIosEyeOff } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { setUserInfo } from "../../Redux/userActions";
import { toast } from "sonner";
import { useGoogleLogin } from '@react-oauth/google';
import { loginUser, loginUserGoogle } from "../../Services/index";
import Loader from "../../components/Loader/loader";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const validateForm = () => {
    try {
      loginSchema.parse(formData);
      setErrors({});
    } catch (error) {
      if (error instanceof z.ZodError) {
        setErrors(error.errors.reduce((acc, curr) => {
          return { ...acc, [curr.path[0]]: curr.message };
        }, {}));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (touched[name]) {
      try {
        loginSchema.pick({ [name]: true }).parse({ [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
      } catch (error) {
        if (error instanceof z.ZodError) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error.errors[0].message,
          }));
        }
      }
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched({ ...touched, [name]: true });

    try {
      loginSchema.pick({ [name]: true }).parse({ [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    } catch (error) {
      if (error instanceof z.ZodError) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.errors[0].message,
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    console.log(formData);
    setLoading(true);
    try {
      const response = await loginUser(formData);
      console.log(response);
      if (response?.status) {
        setLoading(false);
        dispatch(setUserInfo(response?.data));
        localStorage.setItem("zionix-token", response?.token);
        toast.success(response?.message);
        navigate('/');
      }
      else {
        setLoading(false);
        toast.error(response?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const handleGoogleLoginSuccess = async (tokenResponse) => {
    setLoading(true);

    const accessToken = tokenResponse.access_token;

    try {
      const response = await loginUserGoogle(accessToken);
      console.log(response);
      if (response?.status) {
        dispatch(setUserInfo(response?.data));
        localStorage.setItem("zionix-token", response?.token);
        toast.success(response?.message);
        setLoading(false);
        navigate('/');
      } else {
        toast.error(response?.message);
        setLoading(false);
      }

    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Something went wrong");
    }
  }
  const gloginnew = useGoogleLogin({ onSuccess: handleGoogleLoginSuccess });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <>
      {loading ? <Loader /> :
        <div className="flex items-center justify-center pb-20">
          <Card className='mt-36 w-1/3 px-20 py-10 bg-white/70'>
            <h2 className="text-center text-xl font-semibold mb-5">Welcome to <span className="text-[#005b96] underline">ZIONIX</span></h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-2">
                <Label>Email</Label>
                <Input
                  type='email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder='Email'
                />
                {touched.email && errors.email && <p className='text-red-500 text-sm italic'>{errors.email}</p>}
              </div>
              <div>
                <Label>Password</Label>
                <div className="relative">
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    value={formData.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='Password'
                  />
                  <span onClick={togglePasswordVisibility} className='absolute right-3 top-2 cursor-pointer'>{showPassword ? <IoIosEyeOff size={20} /> : <IoMdEye size={20} />}</span>
                </div>
                {touched.password && errors.password && <p className='text-red-500 text-sm italic'>{errors.password}</p>}
              </div>
              <div className="flex flex-col items-center justify-center gap-3">
                <Button type='submit' className='mt-5 w-full'>Login</Button>
                <p>Or</p>
                <div className="flex items-center justify-center gap-3">
                  <p>Login using:</p>
                  <img src={images.GOOGLELOGO} height={40} width={40} alt="googleLogo" className="cursor-pointer" onClick={gloginnew} />
                </div>
                <p>Don't have an account? <Link to="/register" className='text-blue-500 cursor'>Register</Link></p>
              </div>
            </form>
          </Card>
        </div>}
    </>
  );
};

export default Login;
