import React, { useState, useEffect } from 'react'
import { getAllOrders } from '../../Services/index'
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHeader
} from '../../components/ui/table'
import { formatNumber } from '../../Utils/formatCurrency'
import { Button } from '../../components/ui/button'
import { useNavigate } from 'react-router-dom'
import PaginationTable from '../../components/Pagination/pagination'
import Loader from '../../components/Loader/loader'

const OrderHistory = () => {
    const [allOrders, setAllOrders] = useState([]);
    const token = localStorage.getItem('zionix-token');
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [currentItems, setCurrentItems] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAllOrders = async () => {
            setLoading(true);
            const response = await getAllOrders(token);
            console.log(response);
            if (response?.success) {
                setLoading(false);
                setAllOrders(response?.data);
                setTotalItems(response?.data?.length);
            } else {
                console.error(response?.message);
                setLoading(false);
            }
        }
        fetchAllOrders();
    }, [])

    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(allOrders.slice(indexOfFirstItem, indexOfLastItem));
    }, [currentPage, itemsPerPage, allOrders]);


    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
    const handlePageSizeChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1); // Reset to the first page when changing page size
    };

    console.log("allOrders", allOrders);
    console.log("currentItems", currentItems);
    return (
        <div className='mt-28 px-10'>
            <h1 className='text-2xl text-gray-700 text-center font-semibold'>Order History</h1>
            {loading ? <Loader /> :
                <>
                    <Table className="border border-gray-300 divide-y divide-gray-200 mt-10 mb-10 bg-white/70">
                        <TableHeader>
                            <TableRow className='bg-lightGray/10 hover:bg-lightGray/10 divide-x divide-black/20 font-bold text-gray-500'>
                                <TableCell>Sr No.</TableCell>
                                <TableCell>File Name</TableCell>
                                <TableCell>Order Id</TableCell>
                                <TableCell>Order Date</TableCell>
                                <TableCell>Order Value</TableCell>
                                <TableCell>Order Status</TableCell>
                                <TableCell>Payment Status</TableCell>
                                <TableCell>View Order</TableCell>
                            </TableRow>
                        </TableHeader>
                        {currentItems?.length > 0 ? <TableBody>
                            {currentItems?.map((order, index) => (
                                <TableRow key={index} className="divide-x divide-gray-200">
                                    <TableCell className="border border-gray-300">{index + 1 + (currentPage - 1) * itemsPerPage}</TableCell>
                                    <TableCell className="border border-gray-300">{order?.fileName}</TableCell>
                                    <TableCell className="border border-gray-300">{order?.createdOrder?.id}</TableCell>
                                    <TableCell className="border border-gray-300">{new Date(order?.createdAt).toLocaleDateString()}</TableCell>
                                    <TableCell className="border border-gray-300">{formatNumber(order?.totalAmount)}</TableCell>
                                    <TableCell className="border border-gray-300 text-center">
                                        <p
                                            className={`inline-block px-3 py-1 rounded-full text-white ${order?.status === "pending"
                                                ? "bg-orange-300"
                                                : order?.status === "placed"
                                                    ? "bg-blue-500"
                                                    : order?.status === "delivered"
                                                        ? "bg-green-700"
                                                        : order?.status === "failed"
                                                            ? "bg-red-500"
                                                            : order?.status === "under review"
                                                                ? "bg-yellow-500"
                                                                : "bg-gray-400" // Default color if none of the statuses match
                                                }`}
                                        >
                                            {order?.status}
                                        </p>

                                    </TableCell>
                                    <TableCell className="border border-gray-300 text-center">
                                        <p
                                            className={`inline-block px-3 py-1 rounded-full text-white ${order?.paymentStatus === "paid"
                                                ? "bg-green-700"
                                                : order?.paymentStatus === "failed"
                                                    ? "bg-red-500"
                                                    : "bg-orange-300"
                                                }`}
                                        >
                                            {order?.paymentStatus}
                                        </p>
                                    </TableCell>
                                    <TableCell className="border border-gray-300">
                                        <Button variant='secondary'
                                            onClick={() => navigate(`/viewOrderItems?orderId=${order?._id}`)}
                                        >
                                            View Order
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                            :
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={8} className="text-center font-bold text-xl">No order history found</TableCell>
                                </TableRow>
                            </TableBody>
                        }
                    </Table>
                    {currentItems?.length > 0 && <div>
                        <PaginationTable
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            handlePageChange={handlePageChange}
                            itemsPerPage={itemsPerPage}
                            setItemsPerPage={setItemsPerPage}
                            totalItems={totalItems}
                        />
                    </div>}
                </>
            }
        </div>
    )
}

export default OrderHistory