import React from 'react';
import { Card } from '../../components/ui/card';

const Privacy = () => {
  return (
    <div className="flex justify-center mt-28 mb-10">
      <Card className="w-11/12 md:w-3/4 p-8 bg-white/70">
        <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">Privacy Policy</h1>
        <div className="text-gray-700 leading-relaxed">
          <p className="mb-4">
            Your privacy is of paramount importance to Zionix, and this Privacy Policy outlines how we collect, use, protect, and disclose your information.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">1. Information We Collect</h2>
          <p className="mb-4">
            <strong>Account Registration:</strong> When you create an account, we collect your email address and password.
            To process your orders, we require additional information including your company details, GSTIN, and shipping address.
            While we do not store payment details, our payment processing partner will collect and process your payment information.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">2. How Your Information is Used</h2>
          <p className="mb-4">
            Your information is used to:
            <ul className="list-disc ml-5 mt-2">
              <li>Provide, maintain, and improve our services.</li>
              <li>Process transactions and send related information including confirmations and invoices.</li>
              <li>Communicate with you about products, services, offers, and provide customer service.</li>
              <li>Detect, prevent, and address technical issues.</li>
            </ul>
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">3. Sharing of Your Information</h2>
          <p className="mb-4">
            We do not sell or rent your personal information to third parties. However, we may share your information with third-party vendors, service providers, contractors, or agents who perform services for us or on our behalf and require access to such information to do that work, such as payment processing, data analysis, and email delivery.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">4. Data Security</h2>
          <p className="mb-4">
            We implement high-security measures designed to protect the security of any personal information we process.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">5. Your Privacy Rights</h2>
          <p className="mb-4">
            You have the right to access, correct, update, or request deletion of your personal information. You can also object to the processing of your personal information, ask us to restrict the processing of your personal information, or request portability of your personal information.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">6. Changes to This Privacy Policy</h2>
          <p className="mb-4">
            We may update this Privacy Policy from time to time. The updated version will be indicated by an updated "Effective Date" and the updated version will be effective as soon as it is accessible.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">7. Contact Us</h2>
          <p className="mb-4">
            If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@zionix.in" className="text-blue-600 underline">info@zionix.in</a>.
          </p>

          <p className="mt-6">
            By using our platform, you consent to the collection, use, and sharing of your information as described in this Privacy Policy.
          </p>
        </div>
      </Card>
    </div>
  );
}

export default Privacy;
