import { z } from 'zod';

// Schema for the login form
export const loginSchema = z.object({
    email: z.string().email({message: 'Invalid email address'}),
    password: z.string().min(8, {message: "Password must be at least 8 characters long"}),
})

// Schema for the register form
export const registerSchema = z.object({
    name: z.string().min(3, {message: "Name must be at least 3 characters long"}),
    email: z.string().email({message: 'Invalid email address'}),
    password: z.string().min(8, {message: "Password must be at least 8 characters long"}),
    confirmPassword: z.string().min(8, {message: "Password must be at least 8 characters long"}),
    phoneNumber: z.string().min(10, {message: "Phone number must be at least 10 characters long"})
})

// Schema for the profile form

export const profileSchema = z.object({
    firstName: z.string().min(3, {message: "First Name must be at least 3 characters long"}),
    lastName: z.string().min(3, {message: "Last Name must be at least 3 characters long"}),
    email: z.string().email({message: 'Invalid email address'}),
    phoneNumber: z.string().min(10, {message: "Phone number must be at least 10 characters long"}),
    billingName: z.string().min(3, {message: "Billing Name must be at least 3 characters long"}),
    // gstin: z.string().min(15, {message: "GSTIN must be at least 15 characters long"}),
    addressLine1: z.string().min(3, {message: "Address Line 1 must be at least 3 characters long"}),
    // addressLine2: z.string().min(3, {message: "Address Line 2 must be at least 3 characters long"}),
    city: z.string().min(3, {message: "City must be at least 3 characters long"}),
    state: z.string().min(3, {message: "State must be at least 3 characters long"}),
    pincode: z.string().min(6, {message: "Pincode must be at least 6 characters long"}),
    shippingFirstName: z.string().min(3, {message: "Shipping First Name must be at least 3 characters long"}),
    shippingLastName: z.string().min(3, {message: "Shipping Last Name must be at least 3 characters long"}),
    shippingEmail: z.string().email({message: 'Invalid email address'}),
    shippingPhoneNumber: z.string().min(10, {message: "Shipping Phone number must be at least 10 characters long"}),
    shippingBillingName: z.string().min(3, {message: "Shipping Billing Name must be at least 3 characters long"}),
    // shippingGstin: z.string().min(15, {message: "Shipping GSTIN must be at least 15 characters long"}),
    shippingAddressLine1: z.string().min(3, {message: "Shipping Address Line 1 must be at least 3 characters long"}),
    // shippingAddressLine2: z.string().min(3, {message: "Shipping Address Line 2 must be at least 3 characters long"}),
    shippingCity: z.string().min(3, {message: "Shipping City must be at least 3 characters long"}),
    shippingState: z.string().min(3, {message: "Shipping State must be at least 3 characters long"}),
    shippingPincode: z.string().min(6, {message: "Shipping Pincode must be at least 6 characters long"}),
})