import React from 'react'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../ui/select'
import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
} from '../ui/pagination'

const PaginationTable = ({
    currentPage,
    setCurrentPage,
    handlePageChange,
    itemsPerPage,
    setItemsPerPage,
    totalItems
}) => {

    return (
        <div className='flex justify-center mb-10'>
            <Pagination className='ml-56'>
                <PaginationContent>
                    <PaginationItem>
                        <PaginationPrevious
                            className={currentPage === 1 ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-darkBlue/70 hover:text-white'}
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        />
                    </PaginationItem>
                    {[...Array(Math.ceil(totalItems / itemsPerPage)).keys()].map(page => (
                        <PaginationItem key={page}>
                            <PaginationLink
                                href="#"
                                className={currentPage === page + 1 ? 'bg-darkBlue text-white' : 'text-textBlue hover:bg-darkBlue/70 hover:text-white'}
                                active={currentPage === page + 1}
                                onClick={() => handlePageChange(page + 1)}
                            >
                                {page + 1}
                            </PaginationLink>
                        </PaginationItem>
                    ))}
                    <PaginationItem>
                        <PaginationNext
                            className={currentPage === Math.ceil(totalItems / itemsPerPage) ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-darkBlue/70 hover:text-white'}
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(totalItems / itemsPerPage)))}
                        />
                    </PaginationItem>
                </PaginationContent>
            </Pagination>
            <Select
                onValueChange={(value) => setItemsPerPage(Number(value))}
                value={itemsPerPage.toString()} // Convert to string for Select component
            >
                <SelectTrigger className="w-[200px] mr-10">
                    <SelectValue placeholder={`${itemsPerPage} per page`} />
                </SelectTrigger>
                <SelectContent>
                    {[25, 50, 100].map(size => (
                        <SelectItem
                            key={size}
                            value={size.toString()} // Convert to string for Select component
                            className={itemsPerPage === size ? 'bg-darkBlue/70 text-white' : ''}
                        >
                            {size} per page
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>

        </div>
    )
}

export default PaginationTable