import React, { useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from '../Pages/Home/home';
import Login from '../Pages/Login/login';
import Register from '../Pages/Register/register';
import UploadBOM from '../Pages/UploadBOM/uploadBOM';
import AssignColumns from '../Pages/AssignColumns/assignColumns';
import Result from '../Pages/Result/result';
import UserProfile from '../Pages/UserProfile/userProfile';
import OrderHistory from '../Pages/OrderHistory/orderHistory';
import ViewOrderItems from '../Pages/ViewOrderItems/viewOrderItems';
import QuoteHistory from '../Pages/QuoteHistory/quoteHistory';
import Terms from '../Pages/TermsAndConditions/terms';
import Privacy from '../Pages/PrivacyPolicy/privacy';

// Define route paths as constants
const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  UPLOAD_BOM: '/uploadBOM',
  ASSIGN_COLUMNS: '/assignColumns',
  RESULT: '/result',
  USER_PROFILE: '/userProfile',
  ORDER_HISTORY: '/orderHistory',
  VIEW_ORDER_ITEMS: '/viewOrderItems',
  QUOTE_HISTORY: '/quoteHistory',
  TERMS: '/terms-and-conditions',
  PRIVACY: '/privacy-policy',
};

const AppRoutes = () => {
  const token = localStorage.getItem('zionix-token');
  const user = useSelector(state => state.user.userInfo);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if ((!token || !user) && ![ROUTES.LOGIN, ROUTES.REGISTER, ROUTES.HOME, ROUTES.TERMS, ROUTES.PRIVACY].includes(location.pathname)) {
      navigate(ROUTES.LOGIN);
    }
  }, [location.pathname, navigate, token, user]);

  return (
    <Routes>
      <Route path="*" element={<Home />} />
      <Route path={ROUTES.HOME} element={<Home />} />
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.REGISTER} element={<Register />} />
      <Route path={ROUTES.UPLOAD_BOM} element={<UploadBOM />} />
      <Route path={ROUTES.ASSIGN_COLUMNS} element={<AssignColumns />} />
      <Route path={ROUTES.RESULT} element={<Result />} />
      <Route path={ROUTES.USER_PROFILE} element={<UserProfile />} />
      <Route path={ROUTES.ORDER_HISTORY} element={<OrderHistory />} />
      <Route path={ROUTES.VIEW_ORDER_ITEMS} element={<ViewOrderItems />} />
      <Route path={ROUTES.QUOTE_HISTORY} element={<QuoteHistory />} />
      <Route path={ROUTES.TERMS} element={<Terms />} />
      <Route path={ROUTES.PRIVACY} element={<Privacy />} />
    </Routes>
  );
};

export default AppRoutes;
