// src/App.js
import React from 'react';
import Navbar from './components/Navbar/navbar';
import Footer from './components/Footer/footer';
import AppRoutes from './Routes/routes';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from './components/ui/sonner';

const App = () => {
  return (
    <BrowserRouter>
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <main className="flex-grow">
          <AppRoutes />
        </main>
        <Footer />
      </div>
      <Toaster richColors />
    </BrowserRouter>
  );
};

export default App;
