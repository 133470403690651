import React, { useState, useEffect } from 'react';
import ProfileModal from '../../components/ProfileModal/profileModal';
import { Button } from '../../components/ui/button';
import { getUserProfile } from '../../Services/index';
import { useSelector } from 'react-redux';
import { Card } from '../../components/ui/card';

const UserProfile = () => {
  const [open, setOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const token = localStorage.getItem('zionix-token');
  const user = useSelector(state => state.user.userInfo);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const response = await getUserProfile(token);
      if (response?.status) {
        setUserProfile(response?.data);
      } else {
        console.error(response?.message);
      }
    };
    fetchUserProfile();
  }, [open]);
  console.log("userProfile new", user);

  return (
    <div className='mt-28 flex flex-col items-center justify-center mb-10'>
      <Card className='w-3/4 p-8 bg-white/70'>
        <h1 className='text-2xl font-semibold text-gray-700 text-center mb-5'>My Account</h1>

        <div className='flex items-center justify-center my-3'>
          <img
            src={user?.profilePicture || `https://ui-avatars.com/api/?name=${encodeURIComponent(user?.name || '')}`}
            alt={`${user?.name || 'User'}'s profile picture`}
            className="rounded-full center"
          />

        </div>

        <div className={`grid ${userProfile ? "grid-cols-3" : "grid-cols-2"} gap-10`}>
          <div>
            <h2 className='text-xl font-bold mb-2'>Contact Information</h2>
            <p><span className='font-bold'>Name:</span> {user?.name}</p>
            <p><span className='font-bold'>E-mail:</span> {user?.email}</p>
            {(userProfile || user?.phoneNumber) && (
              <p><span className='font-bold'>Phone Number:</span> {userProfile?.phoneNumber || user?.phoneNumber}</p>
            )}
            <p><span className='font-bold'>Country:</span> India</p>
          </div>

          {userProfile ? (
            <>
              <div>
                <h2 className='text-xl font-bold mb-2'>Billing Address</h2>
                <p><span className='font-bold'>Name: </span>{userProfile?.firstName} {userProfile?.lastName}</p>
                <p>{userProfile?.billingCompanyName}</p>
                <p>{userProfile?.addressLine1}, {userProfile?.addressLine2}</p>
                <p>{userProfile?.city}, {userProfile?.state}, {userProfile?.pincode}</p>
                <p>India</p>
                <p>{userProfile?.email}</p>
                <p>{userProfile?.phoneNumber}</p>
              </div>

              <div>
                <h2 className='text-xl font-bold mb-2'>Shipping Address</h2>
                <p><span className='font-bold'>Name: </span>{userProfile?.shippingFirstName} {userProfile?.shippingLastName}</p>
                <p>{userProfile?.shippingCompanyName}</p>
                <p>{userProfile?.shippingAddressLine1}, {userProfile?.shippingAddressLine2}</p>
                <p>{userProfile?.shippingCity}, {userProfile?.shippingState}, {userProfile?.shippingPincode}</p>
                <p>India</p>
                <p>{userProfile?.shippingPhoneNumber}</p>
              </div>
            </>
          ) : (
            <div>
              <p className='text-center text-red-500'>
                Please add your address information to start ordering items.
              </p>
            </div>
          )}
        </div>

        <div className='flex items-center justify-end mt-5'>
          <Button onClick={() => setOpen(true)}>
            {userProfile ? "Update Profile" : "Add Profile Data"}
          </Button>
        </div>
      </Card>

      <ProfileModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default UserProfile;
