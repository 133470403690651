/* eslint-disable import/no-anonymous-default-export */
export default {
    LOGOHEADER: require("../assets/navbar/logoheader.svg"),
    HEADERLOGO: require("../assets/navbar/logoheader.png"),
    GOOGLELOGO: require("../assets/login/googleIcon.png"),
    HEROIMAGE: require("../assets/homepage/heroImage.png"),
    DEMOIMAGE: require("../assets/homepage/demo.png"),
    ELCINALOGO: require("../assets/homepage/elcina-logo.png"),
    IESALOGO: require("../assets/homepage/iesa-logo.png"),
    STARTUPINIDA: require("../assets/homepage/startup-india.png"),
    
}