import React from 'react';
import { Card } from '../../components/ui/card';

const Terms = () => {
  return (
    <div className="flex justify-center mt-28 mb-10">
      <Card className="w-11/12 md:w-3/4 p-8 bg-white/70">
        <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">Terms & Conditions</h1>
        <div className="text-gray-700 leading-relaxed">
          <p className="mb-4">
            These terms and conditions outline the rules and regulations for the use of Zionix's services. By accessing this website, engaging with our services, or placing an order, you consent to the following terms and conditions. Please ensure you read and understand them thoroughly.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">1. Order Acceptance and Cancellation</h2>
          <p className="mb-4">
            At Zionix, the acceptance of any order is subject to our written confirmation. Merely acknowledging receipt of an order does not constitute acceptance. Once an order is accepted, it may only be cancelled with our explicit written consent, except for Non-Cancellable Non-Returnable (NCNR) orders. Orders cannot be cancelled or rescheduled once forwarded to the shipment center. Zionix reserves the right to allocate sales and limit product quantities as necessary.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">2. Returns Policy</h2>
          <p className="mb-4">
            Zionix generally discourages returns. However, in exceptional cases, returns may be considered with prior written approval from Zionix, excluding NCNR orders which are final and non-returnable.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">3. Refund Policy</h2>
          <p className="mb-4">
            In exceptional circumstances, refunds will be initiated within 5-7 business days subject to the following T&C:
            <ul className="list-disc ml-5 mt-2">
              <li>Contact customer service to obtain a RMA (Return Material Authorization) before dispatching material.</li>
              <li>Returns must be made within 7 days from the invoice date.</li>
              <li>Returns must be accompanied by the original invoice and a document citing the reason for return.</li>
              <li>Freight charges for returns must be borne by the customer; C.O.D returns are not accepted.</li>
              <li>Military and Space grade products are NCNR.</li>
              <li>Returns for warranty-related issues are subject to the manufacturer’s policy.</li>
              <li>Products returned that are ESD sensitive and not properly handled will not be eligible for credit.</li>
            </ul>
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">4. Pricing</h2>
          <p className="mb-4">
            All prices are stated in INR and exclude taxes, transport, and handling charges, which will be added at dispatch and borne by the customer.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">5. Standard Delivery</h2>
          <p className="mb-4">
            Orders will be delivered within 10-12 business days, barring special circumstances.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">6. Payment Terms</h2>
          <p className="mb-4">
            Payment is accepted in INR only. Customers will receive a Proforma invoice with bank details, merchandise totals, and shipping charges. Stock is reserved for 72 hours; orders are cancelled if payment is not received within 20 business days. Duties and taxes are payable upon receipt of the order.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">7. Jurisdiction and Compliance</h2>
          <p className="mb-4">
            This agreement is governed by the laws of India. Pune courts have exclusive jurisdiction. You agree to comply with all applicable export control laws.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">8. Risk and Ownership</h2>
          <p className="mb-4">
            Risk of loss passes to the customer upon delivery. Ownership remains with Zionix until full payment is received. The customer must insure the goods until full payment is made.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">9. Amendments</h2>
          <p className="mb-4">
            Zionix reserves the right to amend these Terms and Conditions at any time. Continued use of services constitutes acceptance of the new terms.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-2">10. Contact Us</h2>
          <p className="mb-4">
            If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@zionix.in" className="text-blue-600 underline">info@zionix.in</a>.
          </p>
        </div>
      </Card>
    </div>
  );
}

export default Terms;
