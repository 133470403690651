import React from 'react'
import images from '../../Utils/images'
import { Card } from '../../components/ui/card'
import { CiWallet } from "react-icons/ci";
import { BsCart } from "react-icons/bs";
import { CiDeliveryTruck } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate()
  return (
    <div className='text-black'>
      <div className='relative'>
        <img src={images.HEROIMAGE} alt="" className='w-full h-screen object-cover' />
        <div className='absolute inset-0 flex flex-col items-center justify-center text-center text-white'>
          <h1 className='text-5xl font-bold mb-4'>
            UPLOAD YOUR BOM. <br /> WE DO THE REST.
          </h1>
          <p className='text-2xl mt-2'>
            Procure all electronic components more efficiently than ever.
          </p>
          <div>
            <div className='flex items-center justify-between w-[700px] mt-20 cursor-pointer bg-white text-black rounded-md'
            onClick={() => navigate('/uploadBOM')}
            >
              <p className='text-lg pl-5 tracking-widest' >Get Instant Quote</p>
              <button className='py-5 bg-darkBlue text-white rounded-md w-[300px] text-xl border-none'>Upload BOM</button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='text-center bg-gradient-to-t from-slate-100 to-slate-400'>
          <p className='text-textBlue text-5xl font-bold pt-16'>WHAT WE OFFER</p>
          <p className='text-lightGray text-2xl mt-5 mb-5'>Smart procurement and BOM management solutions for manufacturers to <br /> efficiently respond to the rapidly growing demand.</p>
          <div className='flex items-center justify-center gap-20 mt-20'>
            <Card className='px-20 py-8 bg-darkBlue/80 text-white font-bold text-xl'>Instant Quote</Card>
            <Card className='px-20 py-8 bg-darkBlue/80 text-white font-bold text-xl'>Reliable Delivery</Card>
            <Card className='px-7 py-8 bg-darkBlue/80 text-white font-bold text-xl'>Smart BOM management</Card>
          </div>
          <p className='text-textBlue text-5xl font-bold mt-16'>WHO WE ARE</p>
          <p className='text-lightGray text-2xl mt-5 pb-14'>We are a team of innovators and veterans, building a fast paced and reliable <br /> supply chain infrastructure for the Indian Electronics Industry.</p>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center pt-20 bg-gradient-to-r from-slate-900 to-slate-700'>
        {/* <Card className='flex items-center justify-center gap-5 font-bold px-20 py-10 bg-gradient-to-r from-slate-300 to-slate-500'> */}
        <Card className='flex items-center justify-center gap-5 font-bold px-20 py-10 bg-lightBlue border-none'>
          <div>
            <p className='text-2xl text-textPurple font-normal'>We process 45 Million component data to <br />give you</p>
          </div>
          <div className='text-xl text-white'>
            <ul>
              <li>01 Immediate Availability Quotes</li> <br />
              <li>02 Smart Alternate Suggestions</li> <br />
              <li>03 Real time inventory updates</li>
            </ul>
          </div>
        </Card>
        <div>
          <div className=' text-5xl font-bold pt-16 text-center text-white'>WHY CHOOSE US</div>
          <div className='flex items-center justify-center gap-10 mt-16'>
            <Card className='w-1/4 px-8 py-8 bg-gradient-to-r from-slate-300 to-slate-500'>
              <CiWallet size={50} className='bg-darkBlue/50 rounded-full px-2 py-2 text-white mb-2'/>
              <p className='text-xl font-bold'>Get Credit</p>
              <p className='text-lightGray'>Do not worry about working capital. Grow with our credit</p>
            </Card>
            <Card className='w-1/4 px-8 py-8 bg-gradient-to-r from-slate-300 to-slate-500'>
              <BsCart size={50} className='bg-darkBlue/50 rounded-full px-2 py-2 text-white mb-2'/>
              <p className='text-xl font-bold'>Get Lowest Prices</p>
              <p className='text-lightGray'>Get rates lower than the existing market rates</p>
            </Card>
            <Card className='w-1/4 px-8 py-8 bg-gradient-to-r from-slate-300 to-slate-500'>
              <CiDeliveryTruck size={50} className='bg-darkBlue/50 rounded-full px-2 py-2 text-white mb-2'/>
              <p className='text-xl font-bold'>Avoid Disruptions</p>
              <p className='text-lightGray'>Get streamlined procurement for no production halts</p>
            </Card>
          </div>
          <div className='text-center text-3xl text-white mt-20'>Want to chat? Give us a call at +91 77440 26990!</div>
          <div className='flex items-center justify-around gap-10 mt-16 pb-20'>
            <div>
              <p className='text-white text-5xl font-bold'>45M</p>
              <p className='text-white/50 mt-1 text-lg tracking-widest'>COMPONENTS</p>
              <hr className="border-t-2 border-gray-300 mt-4 w-[200px]" />
            </div>
            <div>
              <p className='text-white text-5xl font-bold'>3500+</p>
              <p className='text-white/50 mt-1 text-lg tracking-widest'>MANUFACTURERS</p>
              <hr className="border-t-2 border-gray-300 mt-4 w-[200px]" />
            </div>
            <div>
              <p className='text-white text-5xl font-bold'>97%</p>
              <p className='text-white/50 mt-1 text-lg tracking-widest'>ON-TIME DELIVERY</p>
              <hr className="border-t-2 border-gray-300 mt-4 w-[200px]" />
            </div>
          </div>
        </div>
      </div>
        <div className='bg-gradient-to-t from-sky-400 to-sky-900'>
        <p className='text-white text-5xl font-bold pt-16 text-center mb-20'>Smart BOM Management</p>
        <div className='flex items-center justify-center'>
        <img src={images.DEMOIMAGE} alt="" className='w-5/6 pb-20'/>
        </div>
        <div className='flex items-center justify-center gap-20 pb-20'>
          <img src={images.ELCINALOGO} alt="" className='w-2/12'/>
          <img src={images.IESALOGO} alt="" className='w-2/12'/>
          <img src={images.STARTUPINIDA} alt="" className='w-2/12'/>
        </div>
        </div>
    </div>
  )
}

export default Home