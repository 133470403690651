import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getFileData, processDemands } from '../../Services/index'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHeader
} from '../../components/ui/table'
import { formatNumber } from '../../Utils/formatCurrency'
import { Button } from '../../components/ui/button'
import PaginationTable from '../../components/Pagination/pagination'
import Loader from '../../components/Loader/loader'

const QuoteHistory = () => {
  const [quoteHistory, setQuoteHistory] = useState([])
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [currentItems, setCurrentItems] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('zionix-token')

  useEffect(() => {
    const fetchQuoteHistory = async () => {
      try {
        const response = await getFileData(token)
        if (response?.status) {
          setQuoteHistory(response?.data)
          setLoading(false)
          setTotalItems(response?.data?.length)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchQuoteHistory()
  }, [])

  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setCurrentItems(quoteHistory.slice(indexOfFirstItem, indexOfLastItem));
}, [currentPage, itemsPerPage, quoteHistory]);


const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
const handlePageSizeChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page when changing page size
};

const handleViewQuote = async (fileId) => {
  try {
    console.log(fileId)
    processDemands(token, fileId)
    navigate(`/result?file=${fileId}`)
  } catch (error) {
    console.log(error)
  }
}

  console.log("quoteHistory", currentItems)
  return (
    <div className='mt-28 px-10'>
      <h1 className='text-2xl text-gray-700 text-center font-semibold'>Quote History</h1>
      {loading ? <Loader />:
      <>
      <Table className="border border-gray-300 divide-y divide-gray-200 mt-10 mb-10 bg-white/70">
        <TableHeader>
          <TableRow className='bg-lightGray/10 hover:bg-lightGray/10 divide-x divide-black/20 font-bold text-gray-500'>
            <TableCell>Sr No.</TableCell>
            <TableCell>File Name</TableCell>
            <TableCell>BOM Id</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Last updated on</TableCell>
            <TableCell>View quote</TableCell>
          </TableRow>
        </TableHeader>
        {currentItems?.length > 0 ? <TableBody>
          {currentItems?.map((quote, index) => (
            <TableRow key={index} className="divide-x divide-gray-200">
              <TableCell className="border border-gray-300">{index + 1 + (currentPage - 1) * itemsPerPage}</TableCell>
              <TableCell className="border border-gray-300">{quote?.file_name}</TableCell>
              <TableCell className="border border-gray-300">{quote?.bom_id}</TableCell>
              <TableCell className="border border-gray-300">{new Date(quote?.createdAt).toLocaleDateString()}</TableCell>
              <TableCell className="border border-gray-300">{new Date(quote?.updatedAt).toLocaleDateString()}</TableCell>
              
              <TableCell className="border border-gray-300">
                <Button variant='secondary'
                  onClick={()=>{
                    console.log("here",quote?._id)
                    const fileId = quote?._id
                    handleViewQuote(fileId)}}
                >
                  View Quote
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
          :
          <TableBody>
            <TableRow>
              <TableCell colSpan={8} className="text-center font-bold text-xl">No quote history found</TableCell>
            </TableRow>
          </TableBody>
        }
      </Table>
      {currentItems?.length > 0 &&
      <div>
                <PaginationTable
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    handlePageChange={handlePageChange}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    totalItems={totalItems}
                />
            </div>}
      </>}
    </div>
  )
}

export default QuoteHistory