import React, { useState, useEffect } from 'react';
import { TbCloudUpload } from "react-icons/tb";
import { Button } from '../../components/ui/button';
import { Card } from '../../components/ui/card';
import { MdOutlineCancel } from "react-icons/md";
import { uploadBOM } from '../../Services/index';
import { toast } from 'sonner';
import Loader from '../../components/Loader/loader';
import { useNavigate } from 'react-router-dom';


const UploadBOM = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const token = localStorage.getItem('zionix-token');
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const allowedExtensions = ['csv', 'xls', 'xlsx'];

  const validateFile = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && validateFile(file)) {
      setSelectedFile(file);
      console.log('File selected:', file);
    } else {
      toast.error('Invalid file type. Please upload a CSV, XLS, or XLSX file.');
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (validateFile(file)) {
        setSelectedFile(file);
        console.log('File dropped:', file);
      } else {
        toast.error('Invalid file type. Please upload a CSV, XLS, or XLSX file.');
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
    document.getElementById('fileUpload').click();
  };

  useEffect(()=>{
    console.log('token', token);
    const uploadFile = async () => {
    if(selectedFile){
      setLoading(true);
      try {
        const response = await uploadBOM(token, selectedFile);
        if(response?.status){
          console.log('response', response);
          console.log('response', response?.data);
          setLoading(false);
          setFile(response?.data);
          toast.success(response?.message);
        }
        else{
          setLoading(false);
          toast.error(response?.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error('Error uploading file');
        console.log('Error uploading file:', error);
      }
    }
  }
  uploadFile();
  }, [selectedFile, token])
console.log('file', file);
  return (
    <>
    {loading ? <Loader /> :
    <div className='mt-28 px-20 mb-9'>
    <input
      type="file"
      id="fileUpload"
      style={{ display: 'none' }}
      onChange={handleFileChange}
    />
    <Card
      className='flex flex-col items-center justify-between gap-2 py-10 border-dashed border border-black/30 hover:border-black/70 cursor-pointer'
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onClick={() => document.getElementById('fileUpload').click()}
    >
      <TbCloudUpload size={50} color='skyBlue'/>
      <p className='text-xl'>Upload BOM file</p>
      <p className='text-lightGray text-sm'>Excel files are supported</p>
      <p>Drag and drop files</p>
      <p>-or-</p>
      <Button className='bg-skyBlue hover:bg-skyBlue/80' onClick={handleButtonClick}>
        Browse
      </Button>
    </Card>
    <div className='mt-5'>
      {selectedFile && (
        <div className='flex items-center justify-between'>
          <div className='flex items-center justify-center gap-2'>
          <p>File selected: {selectedFile.name}</p>
          <MdOutlineCancel onClick={()=> setSelectedFile(null)} className='cursor-pointer'/>
          </div>
          <Button
          onClick={()=> navigate(`/assignColumns?file=${file?.fileId}&bomId=${file?.bomId}`)}
          >Proceed</Button>
        </div>
      )}
    </div>
  </div>}
    </>
  );
}

export default UploadBOM;
